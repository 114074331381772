import { qubyNav } from '@/navigation/qubyNav'
import {
  CargoType,
  CargoVolume,
  ExcelReportTemplate,
  GoodOrderBy,
  LocationOrderBy,
  MarketplaceOrderBy,
  PackageType,
  PartnerPartnerWarehouseResponse,
  ReceiverDestinationType,
  Role,
  RunRequestOrderBy,
  SenderDestinationType,
  ShipmentAdditionalServices,
  ShipmentStatus as ShipmentStatusType,
  TariffLevelLocalization,
  UserResponse,
  VatRate,
} from '@/api/generated/qubyApiJava'
import { PaginationPerPageType } from '@/components/moleculs/Table/help/Pagination/Pagination.types'
import { NavigationSections } from '@/navigation/NavICore.types'
import {
  BaseOrderTypeEnum,
  DeliveryServiceResource,
  InvoiceOrderByEnum,
  InvoiceStatusEnum,
  LocationTypes,
  ShipmentAdditionalServicesEnum,
  ShipmentRelationsEnum,
  ShipmentTypesEnum,
  StatisticsDeliveryServicesTabsEnum,
  StatisticsRevenueTabsEnum,
} from '@/api/generated/qubyApiPhp'
import {
  ClientLegalForm,
  ClientStatus,
} from '@/_app/pages/partners/modals/partner/ClientModalForm.types'
import { MonitoringDlvStatus, MonitoringStatus } from '@/api/qubyApiEgo'
import {
  DeliveryDlvEnum,
  DlvStatusEnum,
  ShipmentsDlvStatusEnum,
} from '@/api/qubyApiEgoDlv'

type LangPart<T extends string | number> = Record<T, string>
type NavigationPart<T> = Record<NavigationSections<T>, string>

const invoicesStatus: LangPart<InvoiceStatusEnum> = {
  NEW: 'Новый',
  SENT: 'Отправлен',
  PARTIALLY_PAID: 'Частично оплачен',
  PAID: 'Оплачен',
}
const shipmentDlvStatus: LangPart<ShipmentsDlvStatusEnum> = {
  ACCEPTED: 'Не выгружен',
  UPDATING: 'Выгружен',
  ABORTED: 'Завершен',
  CANCELING: 'Отменяется',
  UPDATING_SUSPENDED: '',
}
const dlvStatus: LangPart<DlvStatusEnum> = {
  SENDER: 'У отправителя',
  COURIER: 'У курьера',
  ACCEPTED_BY_COURIER: 'Принято курьером',
  SORT: 'На складе',
  COURIER2: 'Доставляется получателю',
  PROBLEM: 'Проблема',
  DELIVERED: 'Доставлен',
  RETURNED: 'Возвращен',
  AT_TAKEOUT_POINT: 'В пункте самовывоза',
  RETURNING: 'Возвращается отправителю',
  LOST: 'Потерян',
  PARTIALLY_DELIVERED: 'Частично доставлен',
  CANCELED: 'Отменён',
}
const shipmentsDlvDelivery: LangPart<DeliveryDlvEnum> = {
  PickPoint: 'PickPoint',
  SPSR: 'SPSR',
  CityExpress: 'CityExpress',
  IML: 'IML',
  Hermes: 'Hermes',
  CDEK: 'CDEK',
  CDEKV2: 'CDEKV2',
  Grastin: 'Grastin',
  Boxberry: 'Boxberry',
  GD: 'GD',
  DPD: 'DPD',
  DPD_LATVIA: 'DPD_LATVIA',
  MaxiPost: 'MaxiPost',
  PickUp: 'PickUp',
  PonyExpress: 'PonyExpress',
  YandexDelivery: 'YandexDelivery',
  YandexDeliveryV2: 'YandexDeliveryV2',
  CSE: 'CSE',
  DOSTAVISTA: 'DOSTAVISTA',
  PEK: 'PEK',
  RussianPost: 'RussianPost',
  TEMPOLINE_RUSSIAN_POST: 'TEMPOLINE_RUSSIAN_POST',
  STRIZH: 'STRIZH',
  SelfPickup: 'SelfPickup',
  Logsis: 'Logsis',
  A1: 'A1',
  RussianPostApiEcom: 'RussianPostApiEcom',
  OZON: 'OZON',
  OZON_DELIVERY: 'OZON_DELIVERY',
  DalliService: 'DalliService',
  PbmLatvia: 'PbmLatvia',
  RussianPostApi: 'RussianPostApi',
  Goods: 'Goods',
  Dellin: 'Dellin',
  Novaposhta: 'Novaposhta',
  Kak2cCourier: 'Kak2cCourier',
  UPS_Latvia: 'UPS_Latvia',
  Wildberries: 'Wildberries',
  X5_Post: 'X5_Post',
  Kaspi: 'Kaspi',
  DHL: 'DHL',
  Cainiao: 'Cainiao',
  YandexDeliveryV3: 'YandexDeliveryV3',
  LeroyMerlin: 'LeroyMerlin',
  Lamoda: 'Lamoda',
  Kholodilnik: 'Kholodilnik',
  YANDEX_MARKET: 'YANDEX_MARKET',
  QUBY: 'QUBY',
  PODRYGKA_CDEK: 'PODRYGKA_CDEK',
  PODRYGKA_CSE: 'PODRYGKA_CSE',
  EBULKY: 'EBULKY',
  HALVA: 'HALVA',
  HALVAV2: 'HALVAV2',
}
const invoicesOrderBy: LangPart<InvoiceOrderByEnum> = {
  status: 'Статус',
  sum: 'Сумма',
  dateTo: 'Дата с',
  dateFrom: 'Дата по',
  createdDate: 'Дата создания',
  amountPaid: 'Оплаченная сумма',
  clientId: 'Клиент',
}

const monitoringStatus: LangPart<MonitoringStatus> = {
  SENDER: 'Отправитель',
  ACCEPTED_BY_COURIER: 'Принят курьером',
  COURIER: 'В пути у курьера',
  SORT: 'Сортировка',
  COURIER2: 'Курьерская доставка',
  PROBLEM: 'Проблема с доставкой',
  DELIVERED: 'Доставлено',
  RETURNED: 'Возвращено',
  AT_TAKEOUT_POINT: 'В пункте выдачи',
  RETURNING: 'Возвращается',
  LOST: 'Потеряно',
  PARTIALLY_DELIVERED: 'Частично доставлено',
  CANCELED: 'Отменено',
}
const dlvStatuses: LangPart<MonitoringDlvStatus> = {
  ACCEPTED: 'Заказ создан в DLV',
  UPDATING: 'Заказ выгружен в КС',
  CANCELING: 'Отмена заказа',
  ABORTED: 'Заказ обработан окончательно',
  UPDATING_SUSPENDED: 'Временное удержание статуса',
}
const statisticsTabs: LangPart<StatisticsRevenueTabsEnum> = {
  revenue: 'Выручка',
  weight: 'Вес',
}
const statisticsSDTabs: LangPart<StatisticsDeliveryServicesTabsEnum> = {
  partners: 'Партнеры',
  quby: 'Quby',
}
const senderDestinationType: LangPart<SenderDestinationType> = {
  FROM_WAREHOUSE: 'Заберите с моего склада',
  FROM_ADDRESS: 'Заберите с адреса',
  SELF_DELIVERY: 'Привезу сам на склад Quby',
  FROM_WAREHOUSE_MARKETPLACE: 'Со склада маркетплейса',
}
const shipmentRelationsType: LangPart<ShipmentRelationsEnum> = {
  runRequests: 'С рейсами',
  cargoPackages: 'С местами',
  logs: 'С логами',
}

const cargoPackageType: LangPart<PackageType> = {
  PALLET: 'Палета',
  PILE: 'Валом',
  BOX: 'Коробка',
  DOCUMENTS: 'Документы',
  UNKNOWN: 'Неизвестен',
}
const excelReportTemplate: LangPart<ExcelReportTemplate> = {
  ATEX: 'ATEX',
  QUBY: 'QUBY',
}
const tariffLevelLocalization: LangPart<TariffLevelLocalization> = {
  REGION: 'Регион',
  SETTLEMENT: 'Поселение',
  CITY: 'Город',
}
const locationType: LangPart<LocationTypes> = {
  CLIENT_WAREHOUSE: 'Склад клиента',
  ADDRESS: 'Адрес',
  QUBY_WAREHOUSE: 'Склад Quby',
  MARKETPLACE_WAREHOUSE: 'Склад маркетплейса',
}

const pickupLocationType: LangPart<LocationTypes> = {
  CLIENT_WAREHOUSE: 'Заберите с моего склада',
  ADDRESS: 'Заберите с адреса',
  QUBY_WAREHOUSE: 'Привезу сам на склад Quby',
  MARKETPLACE_WAREHOUSE: 'Со склада маркетплейса',
}

const deliveryLocationType: LangPart<Exclude<LocationTypes, 'QUBY_WAREHOUSE'>> =
  {
    CLIENT_WAREHOUSE: 'Мой склад',
    ADDRESS: 'Определенный адрес',
    MARKETPLACE_WAREHOUSE: 'Склад маркетплейса',
  }
const cargoType: LangPart<CargoType> = {
  BULKY: 'Валом',
  BOX: 'Коробка',
  OVERSIZED: 'Оверсайз',
}

const receiverDestinationType: LangPart<ReceiverDestinationType> = {
  MY_WAREHOUSE: 'Мой склад',
  EXACT_ADDRESS: 'Определенный адрес',
  WAREHOUSE_MARKETPLACE: 'Склад маркетплейса',
}
const MANUAL_LIFT = 'ручной подъём'
const ELEVATOR_LIFT = 'на лифте'
const shipmentAdditionalServices: LangPart<ShipmentAdditionalServices> = {
  PICKUP_MANUAL_LIFT: MANUAL_LIFT,
  PICKUP_ELEVATOR_LIFT: ELEVATOR_LIFT,
  DELIVERY_MANUAL_LIFT: MANUAL_LIFT,
  DELIVERY_ELEVATOR_LIFT: ELEVATOR_LIFT,
  CARGO_INSURANCE: 'страхование груза',
  CASH_ON_DELIVERY_CARD: 'картой',
  CASH_ON_DELIVERY_CASH: 'наличными',
  DOCUMENTS_RETURN: 'возврат сопроводительных документов',
  REVERSE_SHIPMENT: 'обратный рейс',
  SHIPMENT_CONFIRMATION: 'подтверждение времени',
  SCANS: 'сканы документов',
  CRATING: 'обрешётка',
  PALLET_FORMATION: 'формирование паллет',
  PALLET_SELLING: 'продажа паллет',
  ONE_TIME_SERVICES: 'разовые работы в СЦ',
}
const shipmentAdditionalServicesEnum: LangPart<ShipmentAdditionalServicesEnum> =
  {
    PICKUP_MANUAL_LIFT: MANUAL_LIFT,
    PICKUP_ELEVATOR_LIFT: ELEVATOR_LIFT,
    DELIVERY_MANUAL_LIFT: MANUAL_LIFT,
    DELIVERY_ELEVATOR_LIFT: ELEVATOR_LIFT,
    CARGO_INSURANCE: 'страхование груза',
    CASH_ON_DELIVERY_CARD: 'картой',
    CASH_ON_DELIVERY_CASH: 'наличными',
    DOCUMENTS_RETURN: 'возврат сопроводительных документов',
    REVERSE_SHIPMENT: 'обратный рейс',
    SHIPMENT_CONFIRMATION: 'подтверждение времени',
    SCANS: 'сканы документов',
    CRATING: 'обрешётка',
    PALLET_FORMATION: 'формирование паллет',
    PALLET_SELLING: 'продажа паллет',
    ONE_TIME_SERVICES: 'разовые работы в СЦ',
  }
const receiversOrderBy: LangPart<LocationOrderBy> = {
  UPDATED_DATE: '',
  FULL_NAME: '',
  PHONE_NUMBER: '',
}
const receiversType: LangPart<LocationTypes> = {
  CLIENT_WAREHOUSE: '',
  QUBY_WAREHOUSE: '',
  MARKETPLACE_WAREHOUSE: '',
  ADDRESS: '',
}
const marketplaceOrderBy: LangPart<MarketplaceOrderBy> = {
  NAME: '',
}
const goodsOrderBy: LangPart<GoodOrderBy> = {
  UPDATED_DATE: '',
  EXTERNAL_ID: '',
  NAME: '',
  VENDOR_CODE: '',
  PRICE: '',
}
const orderDirection: LangPart<BaseOrderTypeEnum> = {
  ASC: '',
  DESC: '',
  desc: '',
  asc: '',
}

const paginationPerPage: LangPart<PaginationPerPageType> = {
  5: '',
  10: '',
  20: '',
  50: '',
  100: '',
}

const cargoVolume: LangPart<CargoVolume> = {
  SMALL: 'Маленький',
  MEDIUM: 'Средний',
  LARGE: 'Большой',
}

const partnerLegalForm: LangPart<ClientLegalForm> = {
  ООО: 'ООО',
  ИП: 'ИП',
}
const status: LangPart<ClientStatus> = {
  ACTIVE: 'Действуйщий',
  DEMO: 'Демо',
}
const shipmentStatus: LangPart<ShipmentStatusType> = {
  AT_SENDER_PARSE_ERROR: 'Ошибка обработки',
  AT_SENDER_NEW: 'Новое',
  AT_SENDER_PACKED: 'Упаковано',
  AT_SENDER_AWAITING_SELF_PICKUP: 'Ожидает самопривоза',
  AT_SENDER_AWAITING_QUBY_PICKUP: 'Ожидает сбора',
  QUBY_VEHICLE_2_QUBY: 'Доставляется в СЦ Quby',
  AT_QUBY_AWAITING_CONFIRMATION: 'Принято в СЦ Quby',
  AT_QUBY_AWAITING_CORRECTION: 'Принято с ошибками в СЦ Quby',
  AT_QUBY_AWAITING_ROUTING: 'Ожидает маршрутизации',
  AT_QUBY_AWAITING_DELIVERY: 'Ожидает доставки',
  AGENT_VEHICLE_2_AGENT: 'СД: доставляется в СЦ',
  AT_AGENT: 'СД: принято в СЦ',
  AGENT_VEHICLE_2_RECEIVER: 'СД: доставляется',
  AGENT_ERROR: 'СД: ошибка',
  RETURN_AGENT_VEHICLE_2_QUBY: 'СД: едет в СЦ Quby',
  RETURN_AGENT_VEHICLE_2_SENDER: 'СД: возврат в пути',
  AT_AGENT_LOST: 'СД: утеряно',
  QUBY_VEHICLE_2_RECEIVER: 'Доставляется',
  AT_RECEIVER_DONE: 'Доставлено',
  RETURN_QUBY_VEHICLE_2_QUBY: 'Не доставлено, едет в СЦ Quby',
  RETURN_AT_QUBY_AWAITING_CONFIRMATION: 'Возврат прибыл на СЦ Quby',
  RETURN_AT_QUBY_AWAITING_RETURN: 'Ожидает возврата',
  RETURN_QUBY_VEHICLE_2_SENDER: 'Возврат в пути',
  AT_SENDER_RETURNED: 'Возвращено клиенту',
  AT_SENDER_CANCELLED: 'Отменено',
}
const shipmentStatusForSimpleUser: LangPart<ShipmentStatusType> = {
  ...shipmentStatus,
  AT_QUBY_AWAITING_ROUTING: 'Ожидает доставки',
}

const shipmentTypes: LangPart<ShipmentTypesEnum> = {
  TO_THE_DOOR: 'Склад – Дверь',
  FROM_THE_DOOR: 'Дверь – Склад',
  DOOR_TO_DOOR: 'Дверь – Дверь',
  FBO: 'Поставка FBO',
  REVERSE_FBO: 'Обратная поставка FBO',
  DOCUMENTS_RETURN: 'Возврат документов',
  DOOR_WAREHOUSE_DOOR: 'Дверь – Склад – Дверь',
}

const runRequestsOrderBy: LangPart<RunRequestOrderBy> = {
  STATUS: '',
  TYPE: '',
  ORDINAL_NUMBER: '',
  ARRIVAL_DATE: '',
  ARRIVAL_METHOD: '',
  UPDATED_DATE: '',
  DELIVERY_SERVICE_ID: '',
}

const registers: NavigationPart<typeof qubyNav.registers> = {
  vehicles: 'Машины',
  drivers: 'Водители',
  deliveryServices: 'Службы доставки',
  partnerWarehouses: 'Склады',
  goods: 'Товары',
  marketplaceWarehouses: 'Маркетплейсы',
  users: 'Пользователи',
  receivers: 'Получатели',
}
const registersDescription: NavigationPart<typeof qubyNav.registers> = {
  vehicles: 'Информация о машинах и их вместимости',
  drivers: 'Информация о водителях и их статус работы',
  deliveryServices: 'Контактная информация и статус активности служб доставки ',
  partnerWarehouses: 'Адреса и режим работы складов',
  goods: 'Товары и цены',
  marketplaceWarehouses: 'Адреса и режим работы маркетплейсов',
  users: 'Просмотр и редактирование пользователей',
  receivers: 'Контактная информация и статус активности служб доставки ',
}

const labels = {
  dashboard: 'Дешборд',
  shipments: 'Отправления',
  runRequest_admin: 'Заявки на рейс',
  runRequest: 'Заявки на сбор',
  returns: 'Возвраты',
  recipients: 'Получатели',
  products: 'Товары',
  settings: 'Настройки',
  acceptance: 'Приемка',
  registers: 'Справочники',
  partners: 'Клиенты',
  transferToTheDeliveryService: 'Передача в СД',
  yandexRoutes: 'Планирование ЯМ',
  invoices: 'Счета',
  monitoring: 'Мониторинг DLV',
  statisticsOrder: 'Статистика по заказам',
  statisticsSD: 'Статистика по СД',
  shipmentsDlv: 'Список отправлений DLV',
}

const deliveryServicesColumns: Record<keyof DeliveryServiceResource, string> = {
  id: '#',
  name: 'Отображаемое название в Quby',
  legalName: 'Полное название',
  phoneNumber: 'Телефон',
  url: 'Url-адрес ЛК',
  dlvContractId: 'ID контракта в DLV',
  isActive: 'Активен',
  createdAt: 'Создан',
}

const partnerWarehousesColumns: Record<
  keyof PartnerPartnerWarehouseResponse | 'workingTime',
  string
> = {
  id: '#',
  warehouseName: 'Название',
  phoneNumber: 'Телефон',
  workingTime: 'Рабочее время',
  workingTimeFrom: 'С',
  workingTimeTo: 'По',
  address: 'Адрес',
}
const usersColumns: Record<keyof UserResponse, string> = {
  id: '#',
  email: 'Email',
  fullName: 'ФИО',
  tgUserId: 'ТгЮзер',
  tgChatId: 'ТгЧат',
  partnerId: 'Партнёр',
}

const userRole: LangPart<Role> = {
  USER: 'Пользователь',
  PARTNER: 'Клиент',
  QUBY_WAREHOUSE_EMPLOYEE: 'Работник склада Quby',
  QUBY_MANAGER: 'Менеджер Quby',
  QUBY_DISPATCHER: 'Диспетчер Quby',
  QUBY_DRIVER: 'Водитель Quby',
  QUBY_ADMIN: 'Админ Quby',
}
export default Object.freeze({
  navigation: {
    labels: labels,
    registers,
    registersDescription,
  },

  header: {
    title: {
      [qubyNav.shipments.PATH]: 'Отправления',
      [qubyNav.shipments.create.PATH]: 'Создать отправление',
      [qubyNav.runRequests.PATH]: 'Заявки на рейсы',
      [qubyNav.returns.PATH]: 'Возвраты',
      [qubyNav.runRequests.create.PATH]: 'Создать заявку на сбор',
      [qubyNav.clients.PATH]: 'Клиенты',
      [qubyNav.clients.create.PATH]: 'Создать клиента',
      [qubyNav.registers.receivers.PATH]: 'Получатели',
      [qubyNav.shipments.export.PATH]: 'Экспорт отправлений',
      [qubyNav.registers.PATH]: labels.registers,
      [qubyNav.invoices.PATH]: labels.invoices,
      [qubyNav.registers.users.PATH]: registers.users,
      [qubyNav.registers.vehicles.PATH]: registers.vehicles,
      [qubyNav.registers.marketplaceWarehouses.PATH]:
        registers.marketplaceWarehouses,
      [qubyNav.registers.drivers.PATH]: registers.drivers,
      [qubyNav.registers.goods.PATH]: registers.goods,
      [qubyNav.registers.deliveryServices.PATH]: registers.deliveryServices,
      [qubyNav.registers.partnerWarehouses.PATH]: registers.partnerWarehouses,
      [qubyNav.acceptance.PATH]: 'Приемка',
      [qubyNav.shipmentsDlv.PATH]: labels.shipmentsDlv,
      [qubyNav.yandexRoutes.PATH]: 'Планирование ЯМ',
      [qubyNav.transferToTheDeliveryService.PATH]: 'Передача в СД',
      [qubyNav.monitoring.PATH]: labels.monitoring,
      [qubyNav.statisticsOrder.tab.getPathname({ tabName: 'revenue' })]:
        labels.statisticsOrder,
      [qubyNav.statisticsOrder.tab.getPathname({ tabName: 'weight' })]:
        labels.statisticsOrder,
      [qubyNav.statisticsSD.tab.getPathname({ tabName: 'partners' })]:
        labels.statisticsSD,
      [qubyNav.statisticsSD.tab.getPathname({ tabName: 'quby' })]:
        labels.statisticsSD,
    },
  },

  usersTable: {
    header: usersColumns,
  },
  shipments: {
    table: {
      closedAt: 'Закрыто',
      createdAt: 'Создано',
      number: 'Номер',
      partner: 'Клиент',
      receiver: 'Получатель',
      sender: 'Отправитель',
      status: 'Статус',
      deliveryService: 'Служба доставки',
      phoneNumber: 'Телефон получателя',
      address: 'Адрес доставки',
      attachments: 'Вложения',
      type: 'Тип, Вес',
      deliveryDate: 'Интервал доставки',
    },
  },

  receivers: {
    table: {
      fullName: 'ФИО',
      phoneNumber: 'Контактный телефон',
      additionalPhoneNumber: 'Дополнительный телефон',
      address: 'Адрес',
    },
  },
  drivers: {
    table: {
      fullName: 'ФИО',
      phoneNumber: 'Контактный телефон',
      driverLicence: 'Права',
      apiKey: 'Ключ',
      createdAt: 'Создан',
      createdBy: 'Создал',
      fired: 'Работает',
    },
  },
  shipmentsDlv: {
    table: {
      syncRecord: {
        createTs: 'Дата создания',
        num: 'Номер',
        dlvLogin: 'Аккаунт',
        status: 'Статус',
        courierNum: 'Номер отслеживания КС',
        syncState: 'Статус выгрузки',
      },
      courierCode: 'Служба доставки',
    },
  },
  vehicles: {
    table: {
      brand: 'Марка',
      plateNumber: 'Номер',
      description: 'Описание',
      capacity: 'Вместимость',
      numberSts: 'Номер СТС',
      numberVin: 'Номер ВИН',
      createdAt: 'Добавлен',
      active: 'Активен',
    },
  },
  goods: {
    table: {
      vendorCode: 'Артикул',
      name: 'Наименование',
      code: 'Код',
      price: 'Цена',
      createdAt: 'Дата создания',
      updatedAt: 'Дата обновления',
    },
  },
  deliveryServices: {
    table: deliveryServicesColumns,
  },
  partnerWarehouses: {
    table: partnerWarehousesColumns,
  },
  run_request: {
    table: {
      number: 'Номер',
      partner: 'Клиент',
      arrival: 'Прибытие машины',
      type: 'Тип',
      deliveryType: 'Тип доставки',
      deliveryService: 'Служба доставки',
      status: 'Статус',
      cargoType: 'Состав груза',
      address: 'Адрес получателя',
      timeInterval: 'Временной интервал',
      comment: 'Коментарий',
      tags: 'Теги',
      driver: 'Водитель',
    },
  },

  // CONSTANTS

  constants: {
    userRole,
    invoicesStatus,
    shipmentsDlvDelivery,
    shipmentDlvStatus,
    invoicesOrderBy,
    monitoringStatus,
    dlvStatuses,
    statisticsTabs,
    statisticsSDTabs,
    dlvStatus,
    shipmentAdditionalServices,
    shipmentAdditionalServicesEnum,
    cargoVolume,
    paginationPerPage,
    shipmentStatus,
    shipmentStatusForSimpleUser,
    shipmentTypes,
    runRequestsOrderBy,
    receiversOrderBy,
    receiversType,
    goodsOrderBy,
    marketplaceOrderBy,
    orderDirection,
    receiverDestinationType,
    senderDestinationType,
    shipmentRelationsType,
    cargoPackageType,
    excelReportTemplate,
    tariffLevelLocalization,
    cargoType,
    partnerLegalForm,
    status,
    locationType,
    pickupLocationType,
    deliveryLocationType,
    cargoPackageStatus: {
      ACCEPTED: 'Принято',
      DAMAGED: 'Повреждено',
      LOST: 'Утеряно',
      UNPROCESSED: 'Необработано',
    },
    runRequestArrivalMethod: {
      QUBY_CAR: 'Сбор машиной Quby',
      SELF_DELIVERY: 'Самопривоз',
      DELIVERY_SERVICE: 'Службой доставки',
    },
    runRequestStatus: {
      NEW: 'Новая',
      IN_PROGRESS: 'В пути',
      DONE: 'Выполнена',
      CANCELLED: 'Отменена',
      ERROR: 'Ошибка службы доставки',
      ERROR_SETTING_STATUS: 'Ошибка настроек',
    },

    runRequestType: {
      PICKUP: 'Сбор',
      LOCATION_PICKUP: 'Сбор с адреса',
      DELIVERY: 'Доставка',
      RETURN: 'Возврат',
    },
    runRequestTypeAccusative: {
      PICKUP: 'сбор',
      LOCATION_PICKUP: 'сбор с адреса',
      DELIVERY: 'доставку',
      RETURN: 'возврат',
    },
    runRequestTypeTitleHeader: {
      PICKUP: 'сбор',
      LOCATION_PICKUP: 'сбор с адреса',
      DELIVERY: 'доставку',
      RETURN: 'возврат',
    },

    exportDeliveryListType: {
      DELIVERY_LIST: 'Доставочный лист',
      FEE_LIST: 'Лист сборов',
    },

    vatRate: {
      // не знаю зачем здесь NULL. Оставлю пока так
      NULL: '-',
      WITHOUT_VAT: 'Без НДС',
      VAT_0: '0%',
      VAT_10: '10%',
      VAT_20: '20%',
    } as Record<VatRate, string>,
  },

  errors: {
    empty: 'Поле не может быть пустым',
    minValue: '{{field}} должна быть больше {{min}}',
  },
  invoicesTable: {
    header: {
      partnerName: 'Клиент',
      name: 'Название',
      dateFrom: 'С',
      dateTo: 'По',
      sum: 'Сумма',
      amountPaid: 'Оплачено',
      status: 'Статус',
      comment: 'Комментарий',
      createdDate: 'Создано',
      updatedDate: 'Обновлено',
      createdBy: 'Создал',
      updatedBy: 'Обновил',
      shipments: 'Отправления',
      providedServicesRegisterUrl: 'Скачать',
    },
  },

  partnersTable: {
    header: {
      name: 'Название',
      phoneNumber: 'Телефон',
      bank: 'Банк',
      inn: 'ИНН',
      contractDate: 'Дата договора',
      contractNumber: 'Номер договора',
      totalShipmentsCount: 'Отправлений',
      lastShipmentDate: 'Последнее',
      totalNotPayedShipmentsCount: 'Не оплачено',
    },
  },
  MonitoringTable: {
    header: {
      num: 'Входящий номер',
      dlvId: 'Dlv ID',
      courierCode: 'КС',
      courierNum: 'Трек КС',
      status: 'Текущий статус заказа',
    },
    extraHeader: {
      login: 'От кого',
      requestDate: 'Дата запроса к dlv',
      methodType: 'Действие',
      id: 'Логи запроса ',
    },
  },

  marketplaceWarehousesTable: {
    header: {
      name: 'Название',
      marketplaceName: 'Маркетплейс',
      time: 'Время\nработы',
      costBox: 'Цена\nза коробку',
      costPallet: 'Цена\nза палету',
      costMinimum: 'Минимальная\nцена',
      address: 'Адрес',
    },
  },
})
